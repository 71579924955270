import PropTypes from 'prop-types'

import styles from './Select.module.scss'

const Select = ({ className, label, children, onChange, name, value, id, options }) => {
  return (
    <div className={styles['ds-select']}>
      <select onChange={onChange} name={name} value={value || ''} aria-label={label} id={id} className={styles[className]}>
        <option value="">{label}</option>
        {options && (
          options.map((option, idx) => 
            <option key={idx} value={option.value}>{option.label}</option>
          )
        )}
        {children}
      </select>
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string
}

export default Select
