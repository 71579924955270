import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styles from './LoginBox.module.scss'
import Spacer from 'basics/Spacer'
import Button from 'basics/Button/Button'
import { ClassNames } from 'services/classname.service'
import Select from 'basics/Select'
import SpeedBump from 'components/SpeedBump'
import { nanoid } from 'nanoid'

const Links = {
  Business: [
    {
      title: 'Business Online Banking',
      url: 'https://firstbusinessbank.ebanking-services.com/'
    },
    {
      title: 'Remote Deposit',
      url: 'https://dlmlr5.fisglobal.com/directlinkclient/login/800853'
    },
    {
      title: 'Business Credit Cards',
      url: 'https://www.24-7cardaccess.com/Login',
      hasSpeedBump: true
    },
    {
      title: 'Business Check Ordering',
      url: 'https://www.deluxe.com/shopdeluxe/home',
      hasSpeedBump: true
    },
    {
      title: 'Accounts Receivable Financing Login',
      url: 'https://factors.firstbusiness.bank/'
    },
    { title: 'Floorplan Client Login', url: 'https://firstbusinessbank.accesscompass.com/' }
  ],
  Individual: [
    {
      title: 'Online Private Banking',
      url: 'https://cibng.ibanking-services.com/EamWeb/Account/Login.aspx?orgId=217_075905787&FIFID=075905787&brand=217_075905787&appId=CeB&FIORG=217'
    },
    {
      title: 'Private Banking Credit Cards',
      url: 'https://www.24-7cardaccess.com/Login',
      hasSpeedBump: true
    },
    {
      title: 'Investment Portfolio, IRAs & Trust Accounts',
      url: 'https://app.trustreporter.com/TRv5/?bankabvr=FBTI'
    },
    {
      title: 'Company Retirement Plans – Participant Login',
      url: 'https://www.go-retire.com/firstbusinessbank'
    },
    {
      title: 'Private Banking Check Ordering',
      url: 'https://orderpoint.deluxe.com/personal-checks/reorder-home.htm',
      hasSpeedBump: true
    }
  ]
}

const LoginBox = ({ className }) => {
  const [section, setSection] = useState('Business')
  const [link, setLink] = useState('')
  const [showSpeedBump, setShowSpeedBump] = useState(false)
  return (
    <div className="display-flex justify-content-flex-end md-justify-content-flex-start">
      <section className={ClassNames([styles[`ds-login-box`], className])}>
        <div className={styles['ds-login-box__buttons']}>
          <button
            className={`${section === 'Business' ? styles['active'] : ''}`.trim()}
            onClick={() => setSection('Business')}
          >
            Business
          </button>
          <button
            className={`${section === 'Individual' ? styles['active'] : ''}`.trim()}
            onClick={() => setSection('Individual')}
          >
            Individual
          </button>
        </div>
        <div className={ClassNames([styles['ds-login-box__content'], 'text-align-left'])}>
          <h2>Log In To {section} Accounts</h2>
          <Spacer size={5} />
          <Select
            label="Select Account Type"
            onChange={(ev) => setLink(ev.currentTarget.value)}
            value={link != '' ? link : null}
          >
            {Links[section].map((item) => (
              <option key={nanoid()} value={item.url}>
                {item.title}
              </option>
            ))}
          </Select>
          <Spacer size={5} />
          <Button
            type="primaryLight"
            external={true}
            disabled={!link ? true : null}
            onClick={(ev) => {
              const element = Links[section].find((listLink) => link === listLink.url)

              if (element && element.hasSpeedBump) {
                ev.preventDefault()
                setShowSpeedBump(true)
                return false
              } else {
                location.href = link
              }
            }}
          >
            Log In
          </Button>
        </div>
      </section>
      {showSpeedBump && (
        <SpeedBump
          onAccept={() => {
            window.open(link)
          }}
          onCancel={() => setShowSpeedBump(null)}
        />
      )}
    </div>
  )
}

LoginBox.propTypes = {}

export default LoginBox
