import PropTypes from 'prop-types'
import styles from './Hero.module.scss'
import Image from 'basics/Image'
import Heading from 'basics/Heading'
import Container from 'containers/Container'
import SingleColumn from 'layouts/SingleColumn'
import Spacer from 'basics/Spacer'
import { ClassNames } from 'services/classname.service'
import Breadcrumbs from './Breadcrumbs'
import Tags from 'components/Tags/Tags'
import CtaWidgets from '../../../../features/CtaWidgets/CtaWidgets'
import LoginBox from 'features/LoginBox'

function Hero({
                className,
                title,
                content,
                breadcrumbs,
                children,
                data = {},
                pathData = {},
                isHome = false,
                backgroundImage // This backgroundImage is the background image for the page the hero component is used on. The component also has it's own background images - desktopBackground and mobileBackground.
              }) {
  const { headline, subheadline, description, textAlign, lightText, ctaWidgets, tags, width, view, desktopBackground, mobileBackground, heroStripe, heroStripeColor } = data
  const hasBackgroundImage = (desktopBackground?.length || mobileBackground?.length);

  const alignment = textAlign ? textAlign[0]?.shortName : 'center';
  const textAlignment = 'text-' + alignment;

  let containerWidth = 'normal'; // default

  // sometimes width comes through as a string, other times it's an array
  // with an object that has a "shortName" property
  if ( Array.isArray( width ) ) {
    containerWidth = width[ 0 ]?.shortName || 'normal';
  } else if ( typeof width === 'string' ) {
    containerWidth = width;
  }

  return (
    <>
      <section
        className={ClassNames([
          styles[`ds-hero`],
          styles[`ds-hero__${ alignment }`],
          styles[`ds-hero__${containerWidth}`],
          backgroundImage ? styles['ds-hero--with-background'] : null,
          className
        ])}
      >
        {backgroundImage ? (
          <div className={styles['ds-hero__background']}>
            <Image className={styles['ds-hero__background-image']} src={backgroundImage?.fileName} alt={ backgroundImage?.metadata?.alt } />
          </div>
        ) : (
          <Spacer size={ 8 } smSize={ isHome ? 0 : 8 } mdSize={ isHome ? 0 : 8 } />
        )}

        {desktopBackground && (
          <div className={styles['ds-hero__desktopBackground']}>
            <Image className={styles['ds-hero__desktopBackground-image']} src={desktopBackground[0]?.fileName} display={'display-block'} alt={ desktopBackground[0]?.metadata?.alt } />
          </div>
        )}

        {mobileBackground && (
          <div className={styles['ds-hero__mobileBackground']}>
            <Image className={styles['ds-hero__mobileBackground-image']} src={mobileBackground[0]?.fileName} alt={ desktopBackground[0]?.metadata?.alt } />
          </div>
        )}
        <Container className={ ClassNames([ 'display-flex', 'align-items-center', isHome ? styles[ 'home-container' ] : '' ]) }>
          <SingleColumn className={ClassNames([styles[hasBackgroundImage ? 'has-background-image' : ''], textAlignment])} center={ false }>
            {breadcrumbs && <Breadcrumbs path={breadcrumbs || {}} pathData={pathData} />}
            
            {headline && (
              <Heading level={1} className={ClassNames([textAlignment, lightText ? styles['white'] : '', "white-space-pre-wrap" ])}>
                {headline}
              </Heading>
            )}

            <Spacer size={view==='resource' ? 3 : 5} />

            {children && (
              <div className={ClassNames([styles['ds-hero__intro'], styles[`ds-hero__intro--${alignment}`], textAlignment])}>
                {children}
              </div>
            )}

            {subheadline && (
              <div className={ClassNames([styles['ds-hero__intro'], styles[`ds-hero__intro--${alignment}`]])}>
                <div className={ClassNames(['intro-paragraph', backgroundImage ? 'dark' : null, view==='resource' ? 'is-resource' : '', lightText ? styles['white'] : '', textAlignment])} dangerouslySetInnerHTML={{ __html: subheadline }} />
              </div>
            )}

            {description && (
              <div className={ClassNames([styles['ds-hero__intro'], styles[`ds-hero__intro--${alignment}`]])}>
                <div className={ClassNames(["intro-paragraph", lightText ? styles['white'] : '', textAlignment])} dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            )}

            {tags && (
              <div className={ClassNames([textAlignment])}>
                <Tags tags={tags} />
              </div>
            )}

            { isHome && (
              <Spacer size={ 5 } />
            )}

            {ctaWidgets && (
              <div className={ClassNames([styles['ds-hero__cta'], styles[`ds-hero__cta--${alignment}`], textAlignment])}>
                <CtaWidgets ctaWidgets={ctaWidgets} />
              </div>
            )}

            { ( isHome && !hasBackgroundImage ) && (
              <Spacer mdSize={ 10 } smSize={ 0 } size={ 0 } />
            )}
          </SingleColumn>

          { isHome && (
              <LoginBox className="desktop-only" />
          )}
        </Container>
      </section>
      {heroStripe && (
        <div className={ClassNames([styles['ds-hero__stripe'], styles[heroStripeColor[0]?.shortName]])}>
          <div className={styles['stripe-top']}></div>
          <div className={styles['stripe-middle']}></div>
          <div className={styles['stripe-bottom']}></div>
        </div>
      )}

      { isHome && (
        <Container className="mobile-only">
          <LoginBox />
        </Container>
      )}
    </>
  )
}

Hero.propTypes = {
  title: PropTypes.string
}

export default Hero
