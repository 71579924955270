import Link from 'basics/Link'
import { ClassNames } from 'design-system/src/services/classname.service'
import React, { Fragment } from 'react'
import { nanoid } from 'nanoid'
import styles from './Hero.module.scss'

const replacements = {
  'business-banking-solutions': {
    location: 0,
    replacement: 'business-banking-services'
  }
}

const Breadcrumbs = ({ path, pathData = {} }) => {
  const { slug = [] } = path || {}
  const crumbs = Array.isArray(slug) ? slug.slice(0, slug.length - 1) : []

  // page title and url don't match so replace for breadcrumb link
  let formattedCrumbs = []
  crumbs.forEach((crumb) => {
    let newcrumb = crumb.replace('business-banking-solutions','business-banking-services')
    formattedCrumbs.push(newcrumb)
  })

  return (
    <>
      {formattedCrumbs.length > 0 && (
        <div className={ClassNames([styles['ds-hero__breadcrumbs'], 'mb-1'])}>
          {formattedCrumbs.map((breadcrumb, idx) => {
            let name = pathData[breadcrumb.trim()] || ''
            return (
              <Fragment key={nanoid()}>
                {idx > 0 && <span className="mr-1">›</span>}
                <span className="mr-1">
                  {!breadcrumb.trim().includes('#') ? (
                    <Link href={`/${formattedCrumbs.filter((s, i) => i <= idx).join('/')}`}>{name}</Link>
                  ) : (
                    <span className={styles['ds-hero__static']}>{name}</span>
                  )}
                </span>
              </Fragment>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Breadcrumbs
